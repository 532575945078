import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { ChevronRight, ChevronLeft, ArrowLeft } from 'react-feather';

import * as styles from './ProductDetailWinter.module.css';
import { Link } from 'gatsby';
// import Toast from '@components/Toast/Toast';

const ProductDetailWinter = ({ product }) => {
  const en = product.lang === 'en';

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <div>
        <ChevronRight />
      </div>
    ),
    prevArrow: (
      <div>
        <ChevronLeft />
      </div>
    ),
  };

  const handleJSONld = p => {
    if (typeof document !== 'undefined') {
      let price = '25';
      if (p.id === '0002' || p.id === '0006') price = '17';
      if (p.id === '0003' || p.id === '0007') price = '12';
      if (
        p.id === '0004' ||
        p.id === '0008' ||
        p.id === '0009' ||
        p.id === '0010'
      ) {
        price = '10';
      }
      const images = p.images.map(i => {
        const image = require(`@static/img/${i}`).default;
        return `https://www.tizianobiellersport.com${image}`;
      });
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.textContent = JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: !en
          ? `Noleggio ${p.name} a Champoluc - Valle d'Aosta`
          : `${p.name} Rental in Champoluc - Valle d'Aosta`,
        description: `${p.description_small.substr(0, 120)}...`,
        image: images,
        brand: 'Nordica, Elan, Fischer - Tiziano Bieller Sport',
        category: !en ? 'Noleggio Sci' : 'Ski Rental',
        model: p.name,
        review: [
          {
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: '5',
            },
            author: {
              '@type': 'Person',
              name: 'Seb Quish',
            },
          },
          {
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: '5',
            },
            author: {
              '@type': 'Person',
              name: 'Alessandro Conca',
            },
          },
          {
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: '5',
            },
            author: {
              '@type': 'Person',
              name: 'Andrea Bottin',
            },
          },
        ],
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.6',
          bestRating: '5',
          ratingCount: '27',
          reviewCount: '27',
          worstRating: '1',
        },
        offers: {
          '@type': 'Offer',
          url: `https://www.tizianobiellersport.com${p.url}`,
          priceCurrency: 'EUR',
          price: price,
          availability: 'https://schema.org/InStock',
        },
      });

      document.head.appendChild(script);
    }
  };

  const renderImages = () => {
    return product.images.map((i, index) => {
      const image = require(`@static/img/${i}`).default;
      return (
        <img
          key={index}
          className={styles.image}
          src={image}
          alt={product.name}
        />
      );
    });
  };

  /* eslint-disable */
  useEffect(() => {
    handleJSONld(product);
  }, []);
  /* eslint-enable */

  return (
    <div className={styles.container}>
      <div className={styles.fullBack}>
        <Link to={!en ? '/inverno' : '/en/winter'}>
          <ArrowLeft />
          <span className={styles.fullBackText}>
            {!en ? 'Torna ai prodotti' : 'Back to products'}
          </span>
        </Link>
      </div>
      <h1 className={styles.full}>{product.name}</h1>
      <div className={styles.left}>
        <Slider {...sliderSettings}>{renderImages()}</Slider>
      </div>
      <div className={styles.right}>
        <h2>
          {!en ? 'NOLEGGIO | STAGIONE 24/25' : 'RENTAL | SEASON 24/25'}
          {/* <small style={{ fontSize: 16, display: 'block', fontWeight: '300' }}>
            {!en
              ? 'Presto gli aggiornamenti per la stagione 23/24'
              : 'Updates for the 23/24 season coming soon'}
          </small> */}
        </h2>

        <div style={{ marginBottom: '20px' }}>
          {/* <Toast type="error">
            <div style={{ marginBottom: '5px', fontSize: '18px' }}>
              Saremo presto online con tutte le tariffe per la stagione
              invernale 2022/2023.
            </div>
            Per qualsiasi info contattaci telefonicamente al{' '}
            <a href="tel:3333408102">333.3408102</a> o via email{' '}
            <a href="mailto:info@tizianobiellersport.com">
              info@tizianobiellersport.com
            </a>
            . Troveremo la soluzione piu' adatta per te
          </Toast> */}

          <table className={styles.table}>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>1g</th>
                <th>2gg</th>
                <th>3gg</th>
                <th>4gg</th>
                <th>5gg</th>
              </tr>
            </thead>
            {product.id === '0001' && (
              <tbody>
                <tr>
                  <td>
                    KIT
                    <small
                      style={{
                        fontSize: '11px',
                        display: 'block',
                        lineHeight: 1,
                        marginBottom: '5px',
                      }}
                    >
                      SCI + SCARPONI + BASTONI + CASCO
                    </small>
                  </td>
                  <td>35&euro;</td>
                  <td>59&euro;</td>
                  <td>87&euro;</td>
                  <td>113&euro;</td>
                  <td>137&euro;</td>
                </tr>
                <tr>
                  <td>SCI</td>
                  <td>25&euro;</td>
                  <td>42&euro;</td>
                  <td>60&euro;</td>
                  <td>77&euro;</td>
                  <td>92&euro;</td>
                </tr>
                <tr>
                  <td>SCARPONE</td>
                  <td>15&euro;</td>
                  <td>20&euro;</td>
                  <td>25&euro;</td>
                  <td>30&euro;</td>
                  <td>35&euro;</td>
                </tr>
                <tr>
                  <td>CASCO</td>
                  <td>10&euro;</td>
                  <td>15&euro;</td>
                  <td>20&euro;</td>
                  <td>25&euro;</td>
                  <td>30&euro;</td>
                </tr>
                <tr>
                  <td>BASTONI</td>
                  <td>4&euro;</td>
                  <td>7&euro;</td>
                  <td>10&euro;</td>
                  <td>13&euro;</td>
                  <td>16&euro;</td>
                </tr>
              </tbody>
            )}

            {product.id === '0005' && (
              <tbody>
                <tr>
                  <td>
                    KIT
                    <small
                      style={{
                        fontSize: '11px',
                        display: 'block',
                        lineHeight: 1,
                        marginBottom: '5px',
                      }}
                    >
                      SKI + SKI BOOTS + STICKS + HELMET
                    </small>
                  </td>
                  <td>35&euro;</td>
                  <td>59&euro;</td>
                  <td>87&euro;</td>
                  <td>113&euro;</td>
                  <td>137&euro;</td>
                </tr>
                <tr>
                  <td>SKI</td>
                  <td>25&euro;</td>
                  <td>42&euro;</td>
                  <td>60&euro;</td>
                  <td>77&euro;</td>
                  <td>92&euro;</td>
                </tr>
                <tr>
                  <td>SKI BOOTS</td>
                  <td>15&euro;</td>
                  <td>20&euro;</td>
                  <td>25&euro;</td>
                  <td>30&euro;</td>
                  <td>35&euro;</td>
                </tr>
                <tr>
                  <td>HELMET</td>
                  <td>10&euro;</td>
                  <td>15&euro;</td>
                  <td>20&euro;</td>
                  <td>25&euro;</td>
                  <td>30&euro;</td>
                </tr>
                <tr>
                  <td>STICKS</td>
                  <td>4&euro;</td>
                  <td>7&euro;</td>
                  <td>10&euro;</td>
                  <td>13&euro;</td>
                  <td>16&euro;</td>
                </tr>
              </tbody>
            )}

            {product.id === '0002' && (
              <tbody>
                <tr>
                  <td>
                    KIT
                    <small
                      style={{
                        fontSize: '11px',
                        display: 'block',
                        lineHeight: 1,
                        marginBottom: '5px',
                      }}
                    >
                      SCI + SCARPONI + BASTONI + CASCO
                    </small>
                  </td>
                  <td>20&euro;</td>
                  <td>33&euro;</td>
                  <td>51&euro;</td>
                  <td>65&euro;</td>
                  <td>77&euro;</td>
                </tr>
                <tr>
                  <td>SCI</td>
                  <td>15&euro;</td>
                  <td>22&euro;</td>
                  <td>30&euro;</td>
                  <td>37&euro;</td>
                  <td>42&euro;</td>
                </tr>
                <tr>
                  <td>SCARPONE</td>
                  <td>10&euro;</td>
                  <td>15&euro;</td>
                  <td>20&euro;</td>
                  <td>25&euro;</td>
                  <td>30&euro;</td>
                </tr>
                <tr>
                  <td>CASCO</td>
                  <td>10&euro;</td>
                  <td>15&euro;</td>
                  <td>20&euro;</td>
                  <td>25&euro;</td>
                  <td>30&euro;</td>
                </tr>
                <tr>
                  <td>BASTONI</td>
                  <td>3&euro;</td>
                  <td>5&euro;</td>
                  <td>7&euro;</td>
                  <td>9&euro;</td>
                  <td>11&euro;</td>
                </tr>
              </tbody>
            )}

            {product.id === '0006' && (
              <tbody>
                <tr>
                  <td>
                    KIT
                    <small
                      style={{
                        fontSize: '11px',
                        display: 'block',
                        lineHeight: 1,
                        marginBottom: '5px',
                      }}
                    >
                      SKI + SKI BOOTS + STICKS + HELMET
                    </small>
                  </td>
                  <td>20&euro;</td>
                  <td>33&euro;</td>
                  <td>51&euro;</td>
                  <td>65&euro;</td>
                  <td>77&euro;</td>
                </tr>
                <tr>
                  <td>SKI</td>
                  <td>15&euro;</td>
                  <td>22&euro;</td>
                  <td>30&euro;</td>
                  <td>37&euro;</td>
                  <td>42&euro;</td>
                </tr>
                <tr>
                  <td>SKI BOOTS</td>
                  <td>10&euro;</td>
                  <td>15&euro;</td>
                  <td>20&euro;</td>
                  <td>25&euro;</td>
                  <td>30&euro;</td>
                </tr>
                <tr>
                  <td>HELMET</td>
                  <td>10&euro;</td>
                  <td>15&euro;</td>
                  <td>20&euro;</td>
                  <td>25&euro;</td>
                  <td>30&euro;</td>
                </tr>
                <tr>
                  <td>STICKS</td>
                  <td>3&euro;</td>
                  <td>5&euro;</td>
                  <td>7&euro;</td>
                  <td>9&euro;</td>
                  <td>11&euro;</td>
                </tr>
              </tbody>
            )}

            {product.id === '0003' && (
              <tbody>
                <tr>
                  <td>CIASPOLE S/M/L</td>
                  <td>12&euro;</td>
                  <td>20&euro;</td>
                  <td>26&euro;</td>
                  <td>31&euro;</td>
                  <td>36&euro;</td>
                </tr>
                <tr>
                  <td>BASTONCINI</td>
                  <td colSpan="5">Compresi nel prezzo</td>
                </tr>
              </tbody>
            )}

            {product.id === '0007' && (
              <tbody>
                <tr>
                  <td>SNOWSHOES S/M/L</td>
                  <td>12&euro;</td>
                  <td>20&euro;</td>
                  <td>26&euro;</td>
                  <td>31&euro;</td>
                  <td>36&euro;</td>
                </tr>
                <tr>
                  <td>STICKS</td>
                  <td colSpan="5">Included in price</td>
                </tr>
              </tbody>
            )}

            {product.id === '0004' && (
              <tbody>
                <tr>
                  <td>RAMPONCINI S/M/L</td>
                  <td>10&euro;</td>
                  <td>18&euro;</td>
                  <td>24&euro;</td>
                  <td>29&euro;</td>
                  <td>34&euro;</td>
                </tr>
                <tr>
                  <td>BASTONCINI</td>
                  <td colSpan="5">Compresi nel prezzo</td>
                </tr>
              </tbody>
            )}

            {product.id === '0008' && (
              <tbody>
                <tr>
                  <td>CRAMPONS S/M/L</td>
                  <td>10&euro;</td>
                  <td>18&euro;</td>
                  <td>24&euro;</td>
                  <td>29&euro;</td>
                  <td>34&euro;</td>
                </tr>
                <tr>
                  <td>STICKS</td>
                  <td colSpan="5">Included in price</td>
                </tr>
              </tbody>
            )}

            {(product.id === '0009' || product.id === '0010') && (
              <tbody>
                <tr>
                  <td>BOB</td>
                  <td>10&euro;</td>
                  <td>18&euro;</td>
                  <td>24&euro;</td>
                  <td>29&euro;</td>
                  <td>34&euro;</td>
                </tr>
              </tbody>
            )}
          </table>

          <div className={styles.other}>
            {!en && (
              <strong>
                Hai bisogno di qualche informazione aggiuntiva, sei interessato
                ad un noleggio stagionale, o vuoi prenotare la tua attrezzatura?
              </strong>
            )}
            {en && (
              <strong>
                You need some additional information, you are interested in a
                seasonal rental, or do you want to book your equipment?
              </strong>
            )}
            <br />
            {!en && (
              <span>
                Contattaci subito via email{' '}
                <a href="mailto:info@tizianobiellersport.com">
                  info@tizianobiellersport.com
                </a>{' '}
                o telefonicamente <a href="tel:3333408102">333.3408102</a> -{' '}
                <a href="tel:0125308111">0125.308111</a>
              </span>
            )}

            {en && (
              <span>
                Contact us by email{' '}
                <a href="mailto:info@tizianobiellersport.com">
                  info@tizianobiellersport.com
                </a>{' '}
                or by phone <a href="tel:3333408102">333.3408102</a> -{' '}
                <a href="tel:0125308111">0125.308111</a>
              </span>
            )}
          </div>
        </div>
      </div>

      <div
        className={styles.desc}
        dangerouslySetInnerHTML={{ __html: product.description }}
      ></div>

      <div className={styles.fullBackBottom}>
        <Link to={!en ? '/inverno' : '/en/winter'}>
          <span className={styles.fullBackText}>
            {!en ? 'Torna a tutti i prodotti' : 'Back to all the products'}
          </span>
        </Link>
      </div>
    </div>
  );
};

ProductDetailWinter.propTypes = {
  product: PropTypes.object,
};

export default ProductDetailWinter;
